export const police = {
  privacyPolicy: {
    noAuthorized: `
        <h2>Согласие на обработку персональных данных</h2>
        <div>
          <p>
            Я подтверждаю, что являюсь
            <strong>совершеннолетним потребителем</strong> никотинсодержащей
            продукции, и добровольно, своей волей и в своем интересе, нажимая
            кнопку «Продолжить», я даю полное и безоговорочное согласие Обществу
            с ограниченной ответственностью «Дж.Т.И. Россия» (123112, Москва,
            проезд 1-й Красногвардейский, д. 15, этажи 28-32), далее –
            «Компания»,
          </p>
          <p>
            на осуществление обработки с использованием средств автоматизации
            и/или без использования таких средств, в том числе с передачей
            данных по сетям связи общего доступа, включая сбор, запись,
            систематизация, хранение, уточнение (обновление, изменение),
            извлечение, использование, передача (предоставление, доступ),
            трансграничная передача, блокирование, удаление, уничтожение
            полученных от меня или от третьих лиц моих персональных данных,
          </p>
          <p>
            <strong>с целью</strong> развития потребительского опыта при
            использовании продукции, взаимодействия с потребителями продукции,
            включая информирование пользователей продуктовых сайтов о
            деятельности и продукции Компании, проводимых активностях, что
            включает в себя регистрацию и проверку предоставленных конечными
            потребителями данных для доступа на сайты о продукции и к участию в
            программах, мероприятиях и активностях; составление статистической
            отчетности, аналитики данных об активностях потребителей для
            предоставления релевантной информации и повышения качества
            обслуживания, проведения опросов, исследований; организацию работы
            «Горячей линии», сервисов «Обратной связи»; взаимодействие с
            потребителями и направление информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях по любым каналам связи, включая, но не
            ограничиваясь, направление смс-сообщений, сообщений по электронной
            почте, передачу абонентам коротких текстовых сообщений, доступных
            для визуализации посредством использования программного обеспечения,
            установленного на их устройствах, в том числе всплывающих сообщений;
            обработку обращений потребителей; обеспечение работоспособности
            Сайта, в том числе с использованием информационных систем .
          </p>
          <p>
            Я подтверждаю и гарантирую, что предоставленные мной персональные
            данные соответствуют действительности и являются актуальными,
            полными и точными, я уведомлен и согласен, что проверка моих
            персональных данных производится для подтверждения принадлежности
            мне указанных мной данных и моего совершеннолетия и не влечет доступ
            к другим моим персональным данным или обработку каких-либо других
            персональных данных.
          </p>
          <p>
            Я соглашаюсь с тем, что Компания может передавать мои персональные
            данные третьим лицам для обработки и/или поручать обработку моих
            персональных данных третьим лицам в объеме, необходимом для
            достижения цели обработки, в том числе:
          </p>
          <ul>
            <li>
              <p>
                аффилированным лицам группы компаний JTI (в частности, головной
                компании JTI - JT International SA, (8, Рю Казем Раджави, 1202
                Женева, Швейцария),
              </p>
            </li>
            <li>
              <p>
                лицам, привлекаемым для оказания Компании услуг, в частности по
                подтверждению принадлежности потребителю указанных им
                персональных данных, проверке точности и достоверности
                персональных данных, услуг по обработке обращений потребителей,
                в том числе по Горячей линии, и информационному взаимодействию с
                потребителем, по поддержке и обслуживанию информационных систем
                Компании и группы компаний JTI, услуг по курьерскому
                обслуживанию, услуг, предоставляемых аудиторскими,
              </p>
            </li>
          </ul>
          <p>
            С полным перечнем третьих лиц, которым передаются персональные
            данные можно ознакомиться по ссылке
            <a href="/contractors" target="_blank">
              http://winston.ru/contractors
            </a>
          </p>
          <p>
            Настоящее согласие действует до момента прекращения взаимодействия
            со мной, в том числе по моей инициативе. Настоящее согласие может
            быть отозвано мной в любое время одним из следующих способов: 1)
            направив письмо с указанием фамилии, имени, отчества и адреса,
            указанных при регистрации, на адрес электронной почты
            contact@jdata.ru, 2) в разделе «Личный кабинет» на сайте, где
            производилась регистрация, 3) направив письменный запрос в адрес
            Компании, указанный выше.
          </p>
          <p>
            Я обязуюсь незамедлительно информировать, если предоставленные мной
            персональные данные утрачивают актуальность или изменяются.
          </p>
          <p>
            Я согласен с тем, что предоставленные мной персональные данные будут
            удалены в случае отзыва согласия в течение 30 (тридцати) дней с даты
            получения отзыва согласия.
          </p>
          <p>
            Я подтверждаю ознакомление с Политикой Конфиденциальности и принимаю
            ее условия.
          </p>
        </div>
    `,
    isAuthorized: `
        <h2>Согласие на обработку персональных данных</h2>
        <div>
          <p>
            Я подтверждаю, что являюсь
            <strong>совершеннолетним потребителем</strong> никотинсодержащей
            продукции, и добровольно, своей волей и в своем интересе, нажимая
            кнопку «Продолжить», я даю полное и безоговорочное согласие Обществу
            с ограниченной ответственностью «Дж.Т.И. Россия» (123112, Москва,
            проезд 1-й Красногвардейский, д. 15, этажи 28-32), далее –
            «Компания»,
          </p>
          <p>
            на осуществление обработки с использованием средств автоматизации
            и/или без использования таких средств, в том числе с передачей
            данных по сетям связи общего доступа, включая сбор, запись,
            систематизация, хранение, уточнение (обновление, изменение),
            извлечение, использование, передача (предоставление, доступ),
            трансграничная передача, блокирование, удаление, уничтожение
            полученных от меня или от третьих лиц моих персональных данных,
          </p>
          <p>
            <strong>с целью</strong> развития потребительского опыта при
            использовании продукции, взаимодействия с потребителями продукции,
            включая информирование пользователей продуктовых сайтов о
            деятельности и продукции Компании, проводимых активностях, что
            включает в себя регистрацию и проверку предоставленных конечными
            потребителями данных для доступа на сайты о продукции и к участию в
            программах, мероприятиях и активностях; составление статистической
            отчетности, аналитики данных об активностях потребителей для
            предоставления релевантной информации и повышения качества
            обслуживания, проведения опросов, исследований; организацию работы
            «Горячей линии», сервисов «Обратной связи»; взаимодействие с
            потребителями и направление информационных материалов о качественных
            характеристиках и потребительских свойствах продукции, программах,
            мероприятиях и активностях по любым каналам связи, включая, но не
            ограничиваясь, направление смс-сообщений, сообщений по электронной
            почте, передачу абонентам коротких текстовых сообщений, доступных
            для визуализации посредством использования программного обеспечения,
            установленного на их устройствах, в том числе всплывающих сообщений;
            обработку обращений потребителей; обеспечение работоспособности
            Сайта, в том числе с использованием информационных систем .
          </p>
          <p>
            Я подтверждаю и гарантирую, что предоставленные мной персональные
            данные соответствуют действительности и являются актуальными,
            полными и точными, я уведомлен и согласен, что проверка моих
            персональных данных производится для подтверждения принадлежности
            мне указанных мной данных и моего совершеннолетия и не влечет доступ
            к другим моим персональным данным или обработку каких-либо других
            персональных данных.
          </p>
          <p>
            Я соглашаюсь с тем, что Компания может передавать мои персональные
            данные третьим лицам для обработки и/или поручать обработку моих
            персональных данных третьим лицам в объеме, необходимом для
            достижения цели обработки, в том числе:
          </p>
          <ul>
            <li>
              <p>
                аффилированным лицам группы компаний JTI (в частности, головной
                компании JTI - JT International SA, (8, Рю Казем Раджави, 1202
                Женева, Швейцария),
              </p>
            </li>
            <li>
              <p>
                лицам, привлекаемым для оказания Компании услуг, в частности по
                подтверждению принадлежности потребителю указанных им
                персональных данных, проверке точности и достоверности
                персональных данных, услуг по обработке обращений потребителей,
                в том числе по Горячей линии, и информационному взаимодействию с
                потребителем, по поддержке и обслуживанию информационных систем
                Компании и группы компаний JTI, услуг по курьерскому
                обслуживанию, услуг, предоставляемых аудиторскими,
              </p>
            </li>
          </ul>
          <p>
            С полным перечнем третьих лиц, которым передаются персональные
            данные можно ознакомиться по ссылке
            <a href="/contractors" target="_blank">
              http://winston.ru/contractors
            </a>
          </p>
          <p>
            Настоящее согласие действует до момента прекращения взаимодействия
            со мной, в том числе по моей инициативе. Настоящее согласие может
            быть отозвано мной в любое время одним из следующих способов: 1)
            направив письмо с указанием фамилии, имени, отчества и адреса,
            указанных при регистрации, на адрес электронной почты
            contact@jdata.ru, 2) в разделе «Личный кабинет» на сайте, где
            производилась регистрация, 3) направив письменный запрос в адрес
            Компании, указанный выше.
          </p>
          <p>
            Я обязуюсь незамедлительно информировать, если предоставленные мной
            персональные данные утрачивают актуальность или изменяются.
          </p>
          <p>
            Я согласен с тем, что предоставленные мной персональные данные будут
            удалены в случае отзыва согласия в течение 30 (тридцати) дней с даты
            получения отзыва согласия.
          </p>
          <p>
            Я подтверждаю ознакомление с Политикой Конфиденциальности и принимаю
            ее условия.
          </p>
        </div>
    `,
  },
  termsConditions: {
    noAuthorized: `
            <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <div>
          <p>
            Общество с ограниченной ответственностью «Дж.Т.И. Россия» (далее - Оператор, мы, наш, наши), ИНН 7703386329, зарегистрированное по адресу 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32, понимает, что Ваши персональные данные является одной из важнейших ценностей, поэтому придаёт большое значение защите Вашей частной жизни и безопасности Ваших данных.
          </p>
          <p>
            Из настоящей Политики конфиденциальности Вы узнаете, какие персональные данные мы собираем, зачем мы это делаем, как используем собранные данные, а также какие меры принимаем по обеспечению их безопасности для достижения заявленных нами целей обработки персональных данных, собираемых на сайте https://winston.ru/
          </p>
          <p>
            Мы соблюдаем требования российского законодательства в области персональных данных. При обработке персональных данных мы придерживаемся принципов, изложенных в ст. 5 Федерального закона от 27.07.2006 г №152-ФЗ «О персональных данных» (далее – Закон о персональных данных).
          </p>
          <p>
            Просим Вас внимательно изучить Политику конфиденциальности (далее - Политика), чтобы понимать для чего мы собираем и обрабатываем Ваши персональные данные, а также какими правами Вы обладаете в отношении своих персональных данных, которые мы обрабатываем. Мы постарались сделать Политику максимально простой для Вашего понимания.
          </p>
          <p>
            Если у Вас возникли вопросы, пришлите нам электронное письмо на адрес электронной почты, указанный в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>Термины</h4>
          <p>
            Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);
          </p>
          <p>
            Оператор персональных данных (оператор) – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
          </p>
          <div>
            <p>
              Обработка персональных данных – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя, в том числе:
            </p>
            <ul>
              <li>
                <p>сбор</p>
              </li>
              <li>
                <p>запись</p>
              </li>
              <li>
                <p>систематизацию</p>
              </li>
              <li>
                <p>накопление</p>
              </li>
              <li>
                <p>хранение</p>
              </li>
              <li>
                <p>уточнение (обновление, изменение)</p>
              </li>
              <li>
                <p>извлечение</p>
              </li>
              <li>
                <p>использование</p>
              </li>
              <li>
                <p>передачу (распространение, предоставление, доступ)
                </p>
              </li>
              <li>
                <p>обезличивание</p>
              </li>
              <li>
                <p>блокирование</p>
              </li>
              <li>
                <p>удаление</p>
              </li>
              <li>
                <p>уничтожение</p>
              </li>
            </ul>
          </div>
          <p>Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
          <p>Распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</p>
          <p>Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
          <p>Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
          <p>Уничтожение персональных данных – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</p>
          <p>Обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</p>
          <p>Информационная система персональных данных – совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;</p>
          <p>Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
        </div>
        <div>
          <h4>1 Сфера применения </h4>
          <p>Политика предназначена для информирования Вас о наших действиях по сбору, обработке и защите Ваших персональных данных для достижения нами заявленных целей обработки персональных данных, собираемых на сайте https://winston.ru/ (далее – Сайт). </p>
          <p>Обратите внимание, что наш Сайт может содержать ссылки на ресурсы других поставщиков услуг, которые мы не контролируем и на которые не распространяется действие данной Политики.</p>
        </div>
        <div>
          <h4>2 Сбор персональных данных несовершеннолетних</h4>
          <p>Наш Сайт не предназначен для обработки персональных данных несовершеннолетних лиц. Если у Вас есть основания полагать, что несовершеннолетнее лицо предоставило нам свои персональные данные, то просим Вас сообщить нам об этом, направив письмо по адресу, указанному в разделе «Контакты».</p>
        </div>
        <div>
          <h4>3 Для чего мы обрабатываем Ваши персональные данные</h4>
          <p>Мы собираем, обрабатываем и раскрываем Ваши персональные данные только в конкретных и заранее определенных целях.</p>
          <div>
            <p>
              На сайте осуществляется обработка Ваших персональных данных для достижения следующих целей:
            </p>
            <ul>
              <li>
                <p>
                  Развитие потребительского опыта при использовании продукции, взаимодействие с потребителями продукции, включая информирование пользователей продуктовых сайтов о деятельности и продукции Компании, проводимых активностях, что включает в себя:
                  <ul>
                    <li>
                      <p>регистрацию и проверку предоставленных конечными потребителями данных для доступа на сайты о продукции и к участию в программах, мероприятиях и активностях;</p>
                    </li>
                    <li>
                      <p>составление статистической отчетности, аналитики данных об активностях потребителей для предоставления релевантной информации и повышения качества обслуживания, проведения опросов, исследований;</p>
                    </li>
                    <li>
                      <p>организацию работы «Горячей линии», сервисов «Обратной связи»;</p>
                    </li>
                    <li>
                      <p>взаимодействие с потребителями и направление информационных материалов о качественных характеристиках и потребительских свойствах продукции, программах, мероприятиях и активностях по любым каналам связи, включая, но не ограничиваясь,  направление смс-сообщений, сообщений по  электронной почте, передачу абонентам коротких текстовых сообщений, доступных для визуализации посредством использования программного обеспечения, установленного на их устройствах, в том числе всплывающих сообщений;</p>
                    </li>
                    <li>
                      <p>обработку обращений потребителей;</p>
                    </li>
                    <li>
                      <p>обеспечение работоспособности Сайта, в том числе с использованием информационных систем.</p>
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>Ваши персональные данные, которые обрабатываются для указанной цели:</h5>
          <ul>
            <li>
              <p>фамилия, имя, отчество
              </p>
            </li>
            <li>
              <p>пол</p>
            </li>
            <li>
              <p>дата рождения</p>
            </li>
            <li>
              <p>паспортные данные или данные иного документа, удостоверяющего личность </p>
            </li>
            <li>
              <p>контактная информация (адрес электронной почты, номера телефонов)</p>
            </li>
            <li>
              <p>адрес регистрации по месту жительства</p>
            </li>
            <li>
              <p>адрес фактического проживания</p>
            </li>
            <li>
              <p>данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета (СНИЛС)</p>
            </li>
            <li>
              <p>идентификационный номер налогоплательщика</p>
            </li>
            <li>
              <p>данные водительского удостоверения</p>
            </li>
            <li>
              <p>информация об используемых и предпочитаемых марках сигарет</p>
            </li>
            <li>
              <p>информация по обращению и результатах его рассмотрения</p>
            </li>
            <li>
              <p>информация о предоставленном подарке</p>
            </li>
            <li>
              <p>данные, собираемые посредством метрических программ (функциональные файлы cookie)</p>
            </li>
            <li>
              <p>данные, собираемые посредством метрических программ (аналитические файлы cookie)</p>
            </li>
          </ul>
        </div>
        <div>
          <h5>Какие действия мы можем совершать с Вашими персональными данными:</h5>
          <p>сбор, запись, систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление, уничтожение.</p>
          <p>Обработка Ваших персональных данных осуществляется с использованием средств автоматизации и/или без использования таких средств.</p>
          <p>Основанием для обработки Ваших персональных данных является Ваше согласие на обработку персональных данных, предоставленное Вами при регистрации на Сайте или при обращении через Сайт, если иное не предусмотрено российским законодательством в области персональных данных.</p>
          <p>Мы будем обрабатывать Ваши персональные данные до момента прекращения взаимодействия с Вами, в том числе по Вашей инициативе.</p>
        </div>
        <div>
          <h4>4 Получатели персональных данных</h4>
          <div>
            <p>Мы можем передавать Ваши персональные данные третьим лицам, в том числе на территории иностранных государств, а также поручать им обработку Ваших персональных данных в объеме, необходимом для достижения цели, указанной в разделе 3 Политики. В частности, мы можем передавать Ваши персональные данные следующим третьим лицам:</p>
            <ul>
              <li>
                <p>аффилированным лицам группы компаний JTI (в частности, головной компании JTI - JT International SA, (8, Рю Казем Раджави, 1202 Женева, Швейцария),</p>
              </li>
              <li>
                <p>компаниям, оказывающим услуги по поддержке и обслуживанию информационных систем Оператора и группы компаний JTI,</p>
              </li>
              <li>
                <p>компаниям, оказывающим услуги веб-аналитики (метрические программы), в частности,  Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, США;</p>
              </li>
              <li>
                <p>иным лицам, оказывающим услуги в случаях, когда такая передача персональных данных необходима для достижения целей, указанных в разделе 3 Политики.</p>
              </li>
            </ul>
          </div>
          <p>Третьи лица, которые получают Ваши персональные данные от Оператора, обязаны сохранять конфиденциальность, не раскрывать третьим лицам и не распространять персональные данные без Вашего согласия.</p>
        </div>
        <div>
          <h4>5 Ваши права и обязанности в отношении персональных данных</h4>
          <h5>5.1 Общая информация о Ваших правах</h5>
          <div>
            <p>Когда мы осуществляем обработку Ваших персональных данных, у вас есть множество прав, связанных с их обработкой, и вы можете реализовать эти права в любой момент. Ниже Вы можете ознакомиться с Вашими правами:</p>
            <ul>
              <li>
                <p><strong>право на доступ к персональным данным</strong><br>
                  Вы можете получать доступ к своим персональным данным, запрашивать информацию о том, кто и как обрабатывает Ваши данные, поэтому мы предоставляем Вам соответствующую информацию в настоящей Политике.
                </p>
              </li>
              <li>
                <p><strong>право на уточнение персональных данных</strong><br>
                  Вы можете вносить изменения и обновлять Ваши персональные данные в любое время.
                </p>
              </li>
              <li>
                <p><strong>право на блокирование и удаление персональных данных</strong><br>
                  В определенных случаях у Вас есть право потребовать, чтобы мы удалили все Ваши данные. Если Вы решите удалить все персональные данные, уведомите нас об этом. Мы примем соответствующие действия, чтобы отреагировать на Ваш запрос в соответствии с требованиями российского законодательства.
                </p>
              </li>
              <li>
                <p><strong>право на обжалование наших действий или нашего бездействия</strong><br>
                  Вы вправе возражать против определенных видов обработки данных, включая обработку в целях прямого маркетинга (например, получение от нас электронных сообщений или обращений, связанных с различными потенциальными возможностями). Вы вправе обжаловать действия или бездействие Оператора, нарушающие Ваши права и свободы, в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.
                </p>
              </li>
              <li>
                <p><strong>право на возражение против решений, принятых на основании исключительно автоматизированной обработки их персональных данных</strong><br>
                  Решение, порождающее юридические последствия в отношении Вас или иным образом затрагивающее Ваши права и законные интересы, может быть принято на основании исключительно автоматизированной обработки Ваших персональных данных только при наличии Вашего согласия в письменной форме или в случаях, предусмотренных федеральными законами, устанавливающими также меры по обеспечению соблюдения Ваших прав и законных интересов.
                </p>
              </li>
              <li>
                <p><strong>право на отзыв согласия</strong><br>
                  Если Вы ранее дали согласие на обработку Ваших персональных, то Вы вправе в любое время отозвать его, обратившись к нам.
                </p>
              </li>
            </ul>
            <p>Далее мы более подробнее расскажем Вам о том, как вы можете реализовать Ваши права в отношении обработки персональных данных.</p>
          </div>
          <h5>5.2 Условия предоставления информации по Вашим правам</h5>
          <p>Информация о способах обработки Ваших персональных данных предоставляется бесплатно. Мы можем отказать Вам в предоставлении информации, если Ваш запрос является явно необоснованным или чрезмерным, в частности из-за его повторяющегося характера.</p>
          <h5>5.3 Право на доступ к Вашим персональным данным</h5>
          <div>
            <p>Вы имеете право получить от нас достоверную, полную информацию об обработке Ваших персональных данных в удобной для понимания форме, и, если это возможно, доступ к персональным данным и к следующей информации:</p>
            <ul>
              <li>
                <p>подтверждение факта обработки персональных данных оператором;</p>
              </li>
              <li>
                <p>правовые основания и цели обработки персональных данных;</p>
              </li>
              <li>
                <p>цели и применяемые оператором способы обработки персональных данных;</p>
              </li>
              <li>
                <p>наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с оператором или на основании федерального закона;</p>
              </li>
              <li>
                <p>обрабатываемые персональные данные, относящиеся к Вам, источник их получения;</p>
              </li>
              <li>
                <p>сроки обработки персональных данных, в том числе сроки их хранения;</p>
              </li>
              <li>
                <p>порядок осуществления Вами своих прав согласно Закону о персональных данных;</p>
              </li>
              <li>
                <p>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</p>
              </li>
              <li>
                <p>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению оператора, если обработка поручена или будет поручена такому лицу;</p>
              </li>
              <li>
                <p>информацию о способах исполнения оператором обязанностей, установленных статьей 18.1 Закона о персональных данных<sup>1</sup>;</p>
              </li>
              <li>
                <p>иные сведения в соответствии с законодательством Российской Федерации. </p>
              </li>
            </ul>

            <p><sup>1</sup>Указанная статья содержит перечень организационных, правовых и технических мер, которые должен предпринять оператор персональных данных для целей соблюдения требований Закона о персональных данных.</p>
          </div>
          <h5>5.4 Право на уточнение Ваших персональных данных</h5>
          <p>Вы имеете право требовать вносить в них изменения и обновлять эти данные в любое время. Мы понимаем важность этого вопроса. Если вы хотите воспользоваться своим правом, обратитесь к нам, как это указано в п. 5.10.</p>
          <h5>5.5 Право на блокирование и удаление Ваших персональных данных</h5>
          <p>Вы имеете право требовать от нас блокирование и удаления Ваших персональных данных, которые мы обрабатываем. Если мы не можем удалить Ваши персональные данные, то мы вправе заблокировать их на срок до 6 месяцев и в этот срок найти решение по их удалению.</p>
          <h5>5.6 Право на обжалование наших действий или нашего бездействия</h5>
          <p>Вы имеете право возражать против обработки своих персональных данных в ответ на наши действия или наше бездействия. Для этого вы можете обратиться в территориальный орган Роскомнадзора в своем регионе или в суд.</p>
          <h5>5.7 Право на возражение против решений, принятых на основании исключительно автоматизированной обработки персональных данных</h5>
          <p>При принятии решений на основании исключительно автоматизированной обработки персональных данных, порождающих юридические последствия в отношении Вас или иным образом затрагивающих Ваши права и законные интересы мы разъясним Вам порядок принятия такого решения и возможные его юридические последствия, предоставим возможность заявить возражение против такого решения, а также разъясним порядок защиты Вами своих прав и законных интересов. </p>
          <p>Ваше возражение против решений на основании исключительно автоматизированной обработки персональных данных мы рассмотрим в течение тридцати дней со дня его получения и уведомим Вас о результатах рассмотрения такого возражения.</p>
          <h5>5.8 Право на отзыв согласия</h5>
          <p>Если Ваши данные обрабатываются на основании согласия, то вы имеете право отозвать его в любое время. В случае отзыва Вами согласия мы вправе продолжить обработку Ваших персональных данных при наличии иных оснований, предусмотренных российским законодательством в области персональных данных.</p>
          <h5>5.10 Как Вы можете реализовать свои права?</h5>
          <p>Вы можете написать свой запрос на наш адрес: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32, указав в нем сведения о документе, удостоверяющем Вашу личность или личность Вашего представителя (вид документа, серия и номер, кем и когда выдан), Ваше ФИО (или ФИО представителя), информацию о взаимоотношениях с Вами, которые будут подтверждать факт обработки персональных данных нами, суть обращения, Вашу подпись (или подпись Вашего представителя). </p>
          <p>Запрос, направленный по электронной почте, будет признаваться документом, подписанным простой электронной подписью согласно российскому законодательству об электронной подписи.</p>
          <p>Вы можете получить дополнительную информацию о Ваших правах и соответствующие рекомендации, обратившись в Ваш территориальный орган Роскомнадзора.</p>
          <h5>5.11 Как и когда мы можем не реализовать Ваши права</h5>
          <div>
            <p>Мы можем ограничить Ваше право на доступ к Вашим персональным данным в следующих случаях:</p>
            <ul>
              <li>
                <p>обработка персональных данных, включая персональные данные, полученные в результате оперативно-розыскной, контрразведывательной и разведывательной деятельности, осуществляется в целях обороны страны, безопасности государства и охраны правопорядка;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется органами, осуществившими задержание субъекта персональных данных по подозрению в совершении преступления, либо предъявившими субъекту персональных данных обвинение по уголовному делу, либо применившими к субъекту персональных данных меру пресечения до предъявления обвинения, за исключением предусмотренных уголовно-процессуальным законодательством Российской Федерации случаев, если допускается ознакомление подозреваемого или обвиняемого с такими персональными данными;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма;</p>
              </li>
              <li>
                <p>доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется в случаях, предусмотренных законодательством Российской Федерации о транспортной безопасности, в целях обеспечения устойчивого и безопасного функционирования транспортного комплекса, защиты интересов личности, общества и государства в сфере транспортного комплекса от актов незаконного вмешательства.</p>
              </li>
            </ul>
            <p>Ваше право на блокирование и удаление не будет реализовано Оператором, когда мы будем иметь законные основания, предусмотренные российским законодательством в области персональных данных, для продолжения обработки Ваших персональных данных. </p>
            <p>Если мы не можем реализовать Ваше право по Вашему запросу, то мы напишем Вам мотивированный ответ.</p>
          </div>
          <h5>5.12 Ваши обязанности</h5>
          <p>Являясь субъектом персональных данных, Вы обязаны предоставлять Оператору достоверные персональные данные и своевременно сообщать Оператору об изменениях и дополнениях своих персональных данных.</p>
        </div>
        <div>
          <h4>6 Использование файлов Cookie</h4>
          <p><strong>Файлы Cookies</strong> – это небольшой текстовый файл, который хранится на устройстве (компьютере, планшете, смартфоне и т.д.) и содержит информацию о Вашей активности в Интернете. </p>
          <p>Файлы cookies являются одной из технологий, обеспечивающих нормальное функционирование любого сайта, и которые мы используем для автоматического сбора информации и улучшения качества контента. </p>
          <div>
            <p>На нашем Сайте мы используем несколько видов cookies:</p>
            <ul>
              <li>
                <p><strong>Обязательные.</strong> Без таких cookie невозможна корректная работа сайта, они обязательны для использования всеми пользователями. Вы можете отключить их при помощи настроек своего браузера, при этом сайт или его компоненты будут работать некорректно.</p>
              </li>
              <li>
                <p><strong>Функциональные.</strong> Такие cookie помогают в осуществлении навигации по сайту, а также позволяет использовать функционал веб-страницы.</p>
              </li>
              <li>
                <p><strong>Аналитические.</strong> Такие cookie файлы используются для сбора статистических сведений о работе с сайтом. Если Вы хотите контролировать процесс установки файлов cookies, Вы можете установить в свой браузер специальные программы или дополнения, известные как инструменты запрета отслеживания, позволяющие Вам выбирать, какие файлы cookies Вы разрешаете хранить на своем устройстве.</p>
              </li>
            </ul>
            <p>На нашем Сайте также используется сервис веб-аналитики Google. Analytics, чтобы помочь нам проанализировать, как именно Вы пользуетесь нашим Сайтом. Эта информация используется нами для улучшения работы Сайта. Данные веб-аналитики будут передаваться компании Google, Inc. и храниться ею на серверах в США. Мы не используем данные веб-аналитики и инструменты для вашей идентификации, а также для создания профилей пользователей и предоставления целевой рекламы. Мы также не используем на нашем Сайте рекомендательные технологии.</p>
          </div>
          <p>Заблокировать использование файлов cookies можно в настройках Вашего браузера. Однако в случае блокировки файлов cookies, это может повлиять на работу некоторых функций или разделов Сайта.</p>
        </div>
        <div>
          <h4>7 Безопасность данных</h4>
          <p>Персональные данные, которые мы собираем и храним, считаются конфиденциальной информацией. Они защищены от потери, изменения, случайного или несанкционированного доступа, уничтожения, блокирования доступа и других несанкционированных действий согласно российскому законодательству в области персональных данных. Для этого мы применяем технические и организационные меры, предусмотренные российским законодательством в области защиты данных. Мы постоянно совершенствуем наши системы защиты данных.</p>
          <p>Принимая во внимание специфику пространства сети Интернет, мы не может гарантировать, что неуполномоченные третьи лица не смогут обойти меры зашиты, реализуемые нам, или использовать ваши данные в ненадлежащих целях, поскольку такие действия не зависят от принятых нами мер.</p>
          <p>Мы не несем ответственности за получение третьими лицами ваших персональных данных путем получения доступа к вашей учетной записи. Вы обязаны самостоятельно обеспечивать сохранность данных вашей учетной записи.</p>
        </div>
        <div>
          <h4>8 Изменение политики</h4>
          <p>Мы оставляем за собой право вносить изменения в нашу Политику в любое время, поэтому мы опубликуем эти изменения (обновленную Политику) на Сайте, чтобы Вы всегда знали, как мы обрабатываем Ваши персональные данные. Мы просим Вас регулярно просматривать все обновления нашей Политики. </p>
        </div>
        <div>
          <h4>9 Контакты</h4>
          <p>Обращаем Ваше внимание, что указанные в данном пункте контакты используются только для взаимодействия по вопросам обработки и защиты Ваших персональных данных. Обращения, направленные Вами по указанным в Политике контактам, не связанные с защитой данных, не будут рассмотрены. Для вопросов, связанных с обработкой и защитой персональных данных, Вы можете обратиться напрямую написав нам по адресу: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32.</p>
        </div>
    `,
    isAuthorized: `
            <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
        <div>
          <p>
            Общество с ограниченной ответственностью «Дж.Т.И. Россия» (далее - Оператор, мы, наш, наши), ИНН 7703386329, зарегистрированное по адресу 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32, понимает, что Ваши персональные данные является одной из важнейших ценностей, поэтому придаёт большое значение защите Вашей частной жизни и безопасности Ваших данных.
          </p>
          <p>
            Из настоящей Политики конфиденциальности Вы узнаете, какие персональные данные мы собираем, зачем мы это делаем, как используем собранные данные, а также какие меры принимаем по обеспечению их безопасности для достижения заявленных нами целей обработки персональных данных, собираемых на сайте https://winston.ru/
          </p>
          <p>
            Мы соблюдаем требования российского законодательства в области персональных данных. При обработке персональных данных мы придерживаемся принципов, изложенных в ст. 5 Федерального закона от 27.07.2006 г №152-ФЗ «О персональных данных» (далее – Закон о персональных данных).
          </p>
          <p>
            Просим Вас внимательно изучить Политику конфиденциальности (далее - Политика), чтобы понимать для чего мы собираем и обрабатываем Ваши персональные данные, а также какими правами Вы обладаете в отношении своих персональных данных, которые мы обрабатываем. Мы постарались сделать Политику максимально простой для Вашего понимания.
          </p>
          <p>
            Если у Вас возникли вопросы, пришлите нам электронное письмо на адрес электронной почты, указанный в разделе «Контакты».
          </p>
        </div>
        <div>
          <h4>Термины</h4>
          <p>
            Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);
          </p>
          <p>
            Оператор персональных данных (оператор) – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
          </p>
          <div>
            <p>
              Обработка персональных данных – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя, в том числе:
            </p>
            <ul>
              <li>
                <p>сбор</p>
              </li>
              <li>
                <p>запись</p>
              </li>
              <li>
                <p>систематизацию</p>
              </li>
              <li>
                <p>накопление</p>
              </li>
              <li>
                <p>хранение</p>
              </li>
              <li>
                <p>уточнение (обновление, изменение)</p>
              </li>
              <li>
                <p>извлечение</p>
              </li>
              <li>
                <p>использование</p>
              </li>
              <li>
                <p>передачу (распространение, предоставление, доступ)
                </p>
              </li>
              <li>
                <p>обезличивание</p>
              </li>
              <li>
                <p>блокирование</p>
              </li>
              <li>
                <p>удаление</p>
              </li>
              <li>
                <p>уничтожение</p>
              </li>
            </ul>
          </div>
          <p>Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
          <p>Распространение персональных данных – действия, направленные на раскрытие персональных данных неопределенному кругу лиц;</p>
          <p>Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
          <p>Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
          <p>Уничтожение персональных данных – действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных;</p>
          <p>Обезличивание персональных данных – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных;</p>
          <p>Информационная система персональных данных – совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств;</p>
          <p>Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
        </div>
        <div>
          <h4>1 Сфера применения </h4>
          <p>Политика предназначена для информирования Вас о наших действиях по сбору, обработке и защите Ваших персональных данных для достижения нами заявленных целей обработки персональных данных, собираемых на сайте https://winston.ru/ (далее – Сайт). </p>
          <p>Обратите внимание, что наш Сайт может содержать ссылки на ресурсы других поставщиков услуг, которые мы не контролируем и на которые не распространяется действие данной Политики.</p>
        </div>
        <div>
          <h4>2 Сбор персональных данных несовершеннолетних</h4>
          <p>Наш Сайт не предназначен для обработки персональных данных несовершеннолетних лиц. Если у Вас есть основания полагать, что несовершеннолетнее лицо предоставило нам свои персональные данные, то просим Вас сообщить нам об этом, направив письмо по адресу, указанному в разделе «Контакты».</p>
        </div>
        <div>
          <h4>3 Для чего мы обрабатываем Ваши персональные данные</h4>
          <p>Мы собираем, обрабатываем и раскрываем Ваши персональные данные только в конкретных и заранее определенных целях.</p>
          <div>
            <p>
              На сайте осуществляется обработка Ваших персональных данных для достижения следующих целей:
            </p>
            <ul>
              <li>
                <p>
                  Развитие потребительского опыта при использовании продукции, взаимодействие с потребителями продукции, включая информирование пользователей продуктовых сайтов о деятельности и продукции Компании, проводимых активностях, что включает в себя:
                  <ul>
                    <li>
                      <p>регистрацию и проверку предоставленных конечными потребителями данных для доступа на сайты о продукции и к участию в программах, мероприятиях и активностях;</p>
                    </li>
                    <li>
                      <p>составление статистической отчетности, аналитики данных об активностях потребителей для предоставления релевантной информации и повышения качества обслуживания, проведения опросов, исследований;</p>
                    </li>
                    <li>
                      <p>организацию работы «Горячей линии», сервисов «Обратной связи»;</p>
                    </li>
                    <li>
                      <p>взаимодействие с потребителями и направление информационных материалов о качественных характеристиках и потребительских свойствах продукции, программах, мероприятиях и активностях по любым каналам связи, включая, но не ограничиваясь,  направление смс-сообщений, сообщений по  электронной почте, передачу абонентам коротких текстовых сообщений, доступных для визуализации посредством использования программного обеспечения, установленного на их устройствах, в том числе всплывающих сообщений;</p>
                    </li>
                    <li>
                      <p>обработку обращений потребителей;</p>
                    </li>
                    <li>
                      <p>обеспечение работоспособности Сайта, в том числе с использованием информационных систем.</p>
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h5>Ваши персональные данные, которые обрабатываются для указанной цели:</h5>
          <ul>
            <li>
              <p>фамилия, имя, отчество
              </p>
            </li>
            <li>
              <p>пол</p>
            </li>
            <li>
              <p>дата рождения</p>
            </li>
            <li>
              <p>паспортные данные или данные иного документа, удостоверяющего личность </p>
            </li>
            <li>
              <p>контактная информация (адрес электронной почты, номера телефонов)</p>
            </li>
            <li>
              <p>адрес регистрации по месту жительства</p>
            </li>
            <li>
              <p>адрес фактического проживания</p>
            </li>
            <li>
              <p>данные документа, подтверждающего регистрацию в системе индивидуального (персонифицированного) учета (СНИЛС)</p>
            </li>
            <li>
              <p>идентификационный номер налогоплательщика</p>
            </li>
            <li>
              <p>данные водительского удостоверения</p>
            </li>
            <li>
              <p>информация об используемых и предпочитаемых марках сигарет</p>
            </li>
            <li>
              <p>информация по обращению и результатах его рассмотрения</p>
            </li>
            <li>
              <p>информация о предоставленном подарке</p>
            </li>
            <li>
              <p>данные, собираемые посредством метрических программ (функциональные файлы cookie)</p>
            </li>
            <li>
              <p>данные, собираемые посредством метрических программ (аналитические файлы cookie)</p>
            </li>
          </ul>
        </div>
        <div>
          <h5>Какие действия мы можем совершать с Вашими персональными данными:</h5>
          <p>сбор, запись, систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), блокирование, удаление, уничтожение.</p>
          <p>Обработка Ваших персональных данных осуществляется с использованием средств автоматизации и/или без использования таких средств.</p>
          <p>Основанием для обработки Ваших персональных данных является Ваше согласие на обработку персональных данных, предоставленное Вами при регистрации на Сайте или при обращении через Сайт, если иное не предусмотрено российским законодательством в области персональных данных.</p>
          <p>Мы будем обрабатывать Ваши персональные данные до момента прекращения взаимодействия с Вами, в том числе по Вашей инициативе.</p>
        </div>
        <div>
          <h4>4 Получатели персональных данных</h4>
          <div>
            <p>Мы можем передавать Ваши персональные данные третьим лицам, в том числе на территории иностранных государств, а также поручать им обработку Ваших персональных данных в объеме, необходимом для достижения цели, указанной в разделе 3 Политики. В частности, мы можем передавать Ваши персональные данные следующим третьим лицам:</p>
            <ul>
              <li>
                <p>аффилированным лицам группы компаний JTI (в частности, головной компании JTI - JT International SA, (8, Рю Казем Раджави, 1202 Женева, Швейцария),</p>
              </li>
              <li>
                <p>компаниям, оказывающим услуги по поддержке и обслуживанию информационных систем Оператора и группы компаний JTI,</p>
              </li>
              <li>
                <p>компаниям, оказывающим услуги веб-аналитики (метрические программы), в частности,  Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, США;</p>
              </li>
              <li>
                <p>иным лицам, оказывающим услуги в случаях, когда такая передача персональных данных необходима для достижения целей, указанных в разделе 3 Политики.</p>
              </li>
            </ul>
          </div>
          <p>Третьи лица, которые получают Ваши персональные данные от Оператора, обязаны сохранять конфиденциальность, не раскрывать третьим лицам и не распространять персональные данные без Вашего согласия.</p>
        </div>
        <div>
          <h4>5 Ваши права и обязанности в отношении персональных данных</h4>
          <h5>5.1 Общая информация о Ваших правах</h5>
          <div>
            <p>Когда мы осуществляем обработку Ваших персональных данных, у вас есть множество прав, связанных с их обработкой, и вы можете реализовать эти права в любой момент. Ниже Вы можете ознакомиться с Вашими правами:</p>
            <ul>
              <li>
                <p><strong>право на доступ к персональным данным</strong><br>
                  Вы можете получать доступ к своим персональным данным, запрашивать информацию о том, кто и как обрабатывает Ваши данные, поэтому мы предоставляем Вам соответствующую информацию в настоящей Политике.
                </p>
              </li>
              <li>
                <p><strong>право на уточнение персональных данных</strong><br>
                  Вы можете вносить изменения и обновлять Ваши персональные данные в любое время.
                </p>
              </li>
              <li>
                <p><strong>право на блокирование и удаление персональных данных</strong><br>
                  В определенных случаях у Вас есть право потребовать, чтобы мы удалили все Ваши данные. Если Вы решите удалить все персональные данные, уведомите нас об этом. Мы примем соответствующие действия, чтобы отреагировать на Ваш запрос в соответствии с требованиями российского законодательства.
                </p>
              </li>
              <li>
                <p><strong>право на обжалование наших действий или нашего бездействия</strong><br>
                  Вы вправе возражать против определенных видов обработки данных, включая обработку в целях прямого маркетинга (например, получение от нас электронных сообщений или обращений, связанных с различными потенциальными возможностями). Вы вправе обжаловать действия или бездействие Оператора, нарушающие Ваши права и свободы, в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.
                </p>
              </li>
              <li>
                <p><strong>право на возражение против решений, принятых на основании исключительно автоматизированной обработки их персональных данных</strong><br>
                  Решение, порождающее юридические последствия в отношении Вас или иным образом затрагивающее Ваши права и законные интересы, может быть принято на основании исключительно автоматизированной обработки Ваших персональных данных только при наличии Вашего согласия в письменной форме или в случаях, предусмотренных федеральными законами, устанавливающими также меры по обеспечению соблюдения Ваших прав и законных интересов.
                </p>
              </li>
              <li>
                <p><strong>право на отзыв согласия</strong><br>
                  Если Вы ранее дали согласие на обработку Ваших персональных, то Вы вправе в любое время отозвать его, обратившись к нам.
                </p>
              </li>
            </ul>
            <p>Далее мы более подробнее расскажем Вам о том, как вы можете реализовать Ваши права в отношении обработки персональных данных.</p>
          </div>
          <h5>5.2 Условия предоставления информации по Вашим правам</h5>
          <p>Информация о способах обработки Ваших персональных данных предоставляется бесплатно. Мы можем отказать Вам в предоставлении информации, если Ваш запрос является явно необоснованным или чрезмерным, в частности из-за его повторяющегося характера.</p>
          <h5>5.3 Право на доступ к Вашим персональным данным</h5>
          <div>
            <p>Вы имеете право получить от нас достоверную, полную информацию об обработке Ваших персональных данных в удобной для понимания форме, и, если это возможно, доступ к персональным данным и к следующей информации:</p>
            <ul>
              <li>
                <p>подтверждение факта обработки персональных данных оператором;</p>
              </li>
              <li>
                <p>правовые основания и цели обработки персональных данных;</p>
              </li>
              <li>
                <p>цели и применяемые оператором способы обработки персональных данных;</p>
              </li>
              <li>
                <p>наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с оператором или на основании федерального закона;</p>
              </li>
              <li>
                <p>обрабатываемые персональные данные, относящиеся к Вам, источник их получения;</p>
              </li>
              <li>
                <p>сроки обработки персональных данных, в том числе сроки их хранения;</p>
              </li>
              <li>
                <p>порядок осуществления Вами своих прав согласно Закону о персональных данных;</p>
              </li>
              <li>
                <p>информацию об осуществленной или о предполагаемой трансграничной передаче данных;</p>
              </li>
              <li>
                <p>наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению оператора, если обработка поручена или будет поручена такому лицу;</p>
              </li>
              <li>
                <p>информацию о способах исполнения оператором обязанностей, установленных статьей 18.1 Закона о персональных данных<sup>1</sup>;</p>
              </li>
              <li>
                <p>иные сведения в соответствии с законодательством Российской Федерации. </p>
              </li>
            </ul>

            <p><sup>1</sup>Указанная статья содержит перечень организационных, правовых и технических мер, которые должен предпринять оператор персональных данных для целей соблюдения требований Закона о персональных данных.</p>
          </div>
          <h5>5.4 Право на уточнение Ваших персональных данных</h5>
          <p>Вы имеете право требовать вносить в них изменения и обновлять эти данные в любое время. Мы понимаем важность этого вопроса. Если вы хотите воспользоваться своим правом, обратитесь к нам, как это указано в п. 5.10.</p>
          <h5>5.5 Право на блокирование и удаление Ваших персональных данных</h5>
          <p>Вы имеете право требовать от нас блокирование и удаления Ваших персональных данных, которые мы обрабатываем. Если мы не можем удалить Ваши персональные данные, то мы вправе заблокировать их на срок до 6 месяцев и в этот срок найти решение по их удалению.</p>
          <h5>5.6 Право на обжалование наших действий или нашего бездействия</h5>
          <p>Вы имеете право возражать против обработки своих персональных данных в ответ на наши действия или наше бездействия. Для этого вы можете обратиться в территориальный орган Роскомнадзора в своем регионе или в суд.</p>
          <h5>5.7 Право на возражение против решений, принятых на основании исключительно автоматизированной обработки персональных данных</h5>
          <p>При принятии решений на основании исключительно автоматизированной обработки персональных данных, порождающих юридические последствия в отношении Вас или иным образом затрагивающих Ваши права и законные интересы мы разъясним Вам порядок принятия такого решения и возможные его юридические последствия, предоставим возможность заявить возражение против такого решения, а также разъясним порядок защиты Вами своих прав и законных интересов. </p>
          <p>Ваше возражение против решений на основании исключительно автоматизированной обработки персональных данных мы рассмотрим в течение тридцати дней со дня его получения и уведомим Вас о результатах рассмотрения такого возражения.</p>
          <h5>5.8 Право на отзыв согласия</h5>
          <p>Если Ваши данные обрабатываются на основании согласия, то вы имеете право отозвать его в любое время. В случае отзыва Вами согласия мы вправе продолжить обработку Ваших персональных данных при наличии иных оснований, предусмотренных российским законодательством в области персональных данных.</p>
          <h5>5.10 Как Вы можете реализовать свои права?</h5>
          <p>Вы можете написать свой запрос на наш адрес: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32, указав в нем сведения о документе, удостоверяющем Вашу личность или личность Вашего представителя (вид документа, серия и номер, кем и когда выдан), Ваше ФИО (или ФИО представителя), информацию о взаимоотношениях с Вами, которые будут подтверждать факт обработки персональных данных нами, суть обращения, Вашу подпись (или подпись Вашего представителя). </p>
          <p>Запрос, направленный по электронной почте, будет признаваться документом, подписанным простой электронной подписью согласно российскому законодательству об электронной подписи.</p>
          <p>Вы можете получить дополнительную информацию о Ваших правах и соответствующие рекомендации, обратившись в Ваш территориальный орган Роскомнадзора.</p>
          <h5>5.11 Как и когда мы можем не реализовать Ваши права</h5>
          <div>
            <p>Мы можем ограничить Ваше право на доступ к Вашим персональным данным в следующих случаях:</p>
            <ul>
              <li>
                <p>обработка персональных данных, включая персональные данные, полученные в результате оперативно-розыскной, контрразведывательной и разведывательной деятельности, осуществляется в целях обороны страны, безопасности государства и охраны правопорядка;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется органами, осуществившими задержание субъекта персональных данных по подозрению в совершении преступления, либо предъявившими субъекту персональных данных обвинение по уголовному делу, либо применившими к субъекту персональных данных меру пресечения до предъявления обвинения, за исключением предусмотренных уголовно-процессуальным законодательством Российской Федерации случаев, если допускается ознакомление подозреваемого или обвиняемого с такими персональными данными;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется в соответствии с законодательством о противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма;</p>
              </li>
              <li>
                <p>доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц;</p>
              </li>
              <li>
                <p>обработка персональных данных осуществляется в случаях, предусмотренных законодательством Российской Федерации о транспортной безопасности, в целях обеспечения устойчивого и безопасного функционирования транспортного комплекса, защиты интересов личности, общества и государства в сфере транспортного комплекса от актов незаконного вмешательства.</p>
              </li>
            </ul>
            <p>Ваше право на блокирование и удаление не будет реализовано Оператором, когда мы будем иметь законные основания, предусмотренные российским законодательством в области персональных данных, для продолжения обработки Ваших персональных данных. </p>
            <p>Если мы не можем реализовать Ваше право по Вашему запросу, то мы напишем Вам мотивированный ответ.</p>
          </div>
          <h5>5.12 Ваши обязанности</h5>
          <p>Являясь субъектом персональных данных, Вы обязаны предоставлять Оператору достоверные персональные данные и своевременно сообщать Оператору об изменениях и дополнениях своих персональных данных.</p>
        </div>
        <div>
          <h4>6 Использование файлов Cookie</h4>
          <p><strong>Файлы Cookies</strong> – это небольшой текстовый файл, который хранится на устройстве (компьютере, планшете, смартфоне и т.д.) и содержит информацию о Вашей активности в Интернете. </p>
          <p>Файлы cookies являются одной из технологий, обеспечивающих нормальное функционирование любого сайта, и которые мы используем для автоматического сбора информации и улучшения качества контента. </p>
          <div>
            <p>На нашем Сайте мы используем несколько видов cookies:</p>
            <ul>
              <li>
                <p><strong>Обязательные.</strong> Без таких cookie невозможна корректная работа сайта, они обязательны для использования всеми пользователями. Вы можете отключить их при помощи настроек своего браузера, при этом сайт или его компоненты будут работать некорректно.</p>
              </li>
              <li>
                <p><strong>Функциональные.</strong> Такие cookie помогают в осуществлении навигации по сайту, а также позволяет использовать функционал веб-страницы.</p>
              </li>
              <li>
                <p><strong>Аналитические.</strong> Такие cookie файлы используются для сбора статистических сведений о работе с сайтом. Если Вы хотите контролировать процесс установки файлов cookies, Вы можете установить в свой браузер специальные программы или дополнения, известные как инструменты запрета отслеживания, позволяющие Вам выбирать, какие файлы cookies Вы разрешаете хранить на своем устройстве.</p>
              </li>
            </ul>
            <p>На нашем Сайте также используется сервис веб-аналитики Google. Analytics, чтобы помочь нам проанализировать, как именно Вы пользуетесь нашим Сайтом. Эта информация используется нами для улучшения работы Сайта. Данные веб-аналитики будут передаваться компании Google, Inc. и храниться ею на серверах в США. Мы не используем данные веб-аналитики и инструменты для вашей идентификации, а также для создания профилей пользователей и предоставления целевой рекламы. Мы также не используем на нашем Сайте рекомендательные технологии.</p>
          </div>
          <p>Заблокировать использование файлов cookies можно в настройках Вашего браузера. Однако в случае блокировки файлов cookies, это может повлиять на работу некоторых функций или разделов Сайта.</p>
        </div>
        <div>
          <h4>7 Безопасность данных</h4>
          <p>Персональные данные, которые мы собираем и храним, считаются конфиденциальной информацией. Они защищены от потери, изменения, случайного или несанкционированного доступа, уничтожения, блокирования доступа и других несанкционированных действий согласно российскому законодательству в области персональных данных. Для этого мы применяем технические и организационные меры, предусмотренные российским законодательством в области защиты данных. Мы постоянно совершенствуем наши системы защиты данных.</p>
          <p>Принимая во внимание специфику пространства сети Интернет, мы не может гарантировать, что неуполномоченные третьи лица не смогут обойти меры зашиты, реализуемые нам, или использовать ваши данные в ненадлежащих целях, поскольку такие действия не зависят от принятых нами мер.</p>
          <p>Мы не несем ответственности за получение третьими лицами ваших персональных данных путем получения доступа к вашей учетной записи. Вы обязаны самостоятельно обеспечивать сохранность данных вашей учетной записи.</p>
        </div>
        <div>
          <h4>8 Изменение политики</h4>
          <p>Мы оставляем за собой право вносить изменения в нашу Политику в любое время, поэтому мы опубликуем эти изменения (обновленную Политику) на Сайте, чтобы Вы всегда знали, как мы обрабатываем Ваши персональные данные. Мы просим Вас регулярно просматривать все обновления нашей Политики. </p>
        </div>
        <div>
          <h4>9 Контакты</h4>
          <p>Обращаем Ваше внимание, что указанные в данном пункте контакты используются только для взаимодействия по вопросам обработки и защиты Ваших персональных данных. Обращения, направленные Вами по указанным в Политике контактам, не связанные с защитой данных, не будут рассмотрены. Для вопросов, связанных с обработкой и защитой персональных данных, Вы можете обратиться напрямую написав нам по адресу: 123112, г. Москва, 1-й Красногвардейский проезд, д. 15, этажи 28-32.</p>
        </div>
    `,
  },
};
